export const initFormVal = {
    id_name: '', // 筛选编号
    status: '', // 筛选状态
    market: '' // 筛选市场
};
// 筛选状态
export const statusOption = [{
    value: '',
    label: '全部'
}, {
    value: '10',
    label: '审核中'
}, {
    value: '11',
    label: '已撤回'
}, {
    value: '12',
    label: '已驳回'
}, {
    value: '13',
    label: '待启用'
}, {
    value: '1',
    label: '已启用'
}, {
    value: '0',
    label: '已停用'
}];

/**
 * 表格操作按钮
 * @param {number} status 积分方案状态
 * @returns {Array}
*/
export const operationBtn = (status) => [{
    type: 'edit',
    text: '编辑',
    resource: 'purchase_plan_edit',
    status: [11, 12, 0], // 已撤回、已驳回、已停用
    icon: '\ue677'
}, {
    type: 'cancel',
    text: '撤回',
    resource: 'purchase_plan_rollback',
    status: [10, 13], // 审核中、待启用
    icon: '\ue65d'
}, {
    type: 'disable',
    text: '停用',
    resource: 'purchase_plan_disable',
    status: [1], // 已启用
    icon: '\ue64b'
}, {
    type: 'delete',
    text: '删除',
    resource: 'purchase_plan_delete',
    status: [11, 12, 0], // 已撤回、已驳回、已停用
    icon: '\ue678'
}].filter((item) => item.status.indexOf(status) > -1);
