<template>
    <el-dialog
        top="5%"
        :close-on-click-modal="false"
        :title="`${plan.id ? '编辑' : '新增'}积分方案`"
        :visible.sync="dialogVisible"
        width="444px"
        :modal-append-to-body="false"
        class="pop-dialog aops-edit-plan-dialog"
        @closed="hide"
    >
        <div v-if="showTips" class="aop-plan-tips">{{showTips}}</div>
        <el-form ref="form" label-position="left" label-width="80px" :model="form" :rules="rules">
            <template v-for="item in formItems">
                <el-form-item
                    v-if="!item.filterKey || form[item.filterKey] === '2'"
                    :key="item.prop"
                    :prop="item.prop"
                    :label="item.label"
                >
                    <template v-if="item.type === 'input'">
                        <el-input v-model="form[item.prop]" :disabled="item.disabled" :placeholder="item.placeholder" />
                    </template>
                    <template v-else-if="item.type === 'select'">
                        <el-select
                            v-model="form[item.prop]"
                            :placeholder="item.placeholder"
                            @change="(val) => handleChange(val, item.prop)"
                        >
                            <el-option v-for="(i) in item.option" :key="i.value" :label="i.label" :value="i.value"/>
                        </el-select>
                    </template>
                    <template v-else-if="item.type === 'datetime'">
                        <el-date-picker
                            v-model="form[item.prop]"
                            type="datetime"
                            value-format="timestamp"
                            :picker-options="pickerOptions[item.prop]"
                            :placeholder="item.placeholder"
                            :clearable="false"
                            @change="(val) => handleChange(val, item.prop)"
                        ></el-date-picker>
                    </template>
                </el-form-item>
            </template>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" class="confirm-button" size="medium" :loading="btnLoading"  @click="sure">确认</el-button>
            <el-button class="cancel-button" @click="hide">取 消</el-button>
        </span>
    </el-dialog>
</template>

<script>
import { toDate, formatDate } from 'ecoplants-lib';
import api from '@/api/transcation';
import { formItems, initFormVal, rules, discount, getParams } from './data';

export default {
    props: {
        plan: {
            type: Object,
            default: () => ({})
        }
    },
    /**
     * data
     * @returns {*}
    */
    data() {
        /** 现价格校验
         * @param {*} rule 规则
         * @param {*} value 值
         * @param {*} callback 返回
        */
        const validatePresentprice = (rule, value, callback) => {
            const priceReg = /^\d+(\.\d{1,2})?$/;
            if (!(priceReg.test(value) && value >= 0.01 && value <= 9999)) {
                callback('只能输入数字，最多小数点后两位，数值范围0.01-9999');
            } else if (this.form.original_price && Number(this.form.original_price) < Number(value)) {
                callback('现价不能大于原价');
            } else {
                callback();
            }
        };
        /**
         * 结束时间 禁用
         * @param {*} time 日期
         * @returns {boolean}
        */
        const disabledDate = (time) => {
            const curDate = this.timeValid('expired_at');
            return time.getTime() < toDate(formatDate(curDate, 'yyyy-MM-dd')).getTime();
        };
        return {
            formItems,
            showTips: '',
            form: { ...initFormVal },
            dialogVisible: false,
            btnLoading: false, // 确定按钮loading
            pickerOptions: {
                effective_at: {
                    /**
                     * 启用禁用时间, 可以选择两小时之后的时间
                     * @param {*} time 时间
                     * @returns {*}
                    */
                    disabledDate(time) {
                        const curDate = new Date().getTime() + 2 * 60 * 60 * 1000;
                        return time.getTime() < toDate(formatDate(curDate, 'yyyy-MM-dd')).getTime();
                    }
                },
                expired_at: {
                    /**
                     * 结束禁用时间
                     * @param {*} time 时间
                     * @returns {*}
                    */
                    disabledDate(time) { return disabledDate(time); }
                }
            },
            rules: { ...rules,
                price: [{ required: true, message: '请输入现价' }, { validator: validatePresentprice, trigger: 'blur' }] }
        };
    },
    computed: {
        /**
         * 折扣
         * @returns {string}
        */
        discount() {
            let dis = '';
            if (this.form.price && this.form.original_price && this.$refs.form) {
                this.$refs.form.validateField('price', (error) => {
                    if (!error) {
                        this.$refs.form.validateField('original_price', (err) => {
                            if (!err) {
                                dis = discount(this.form.price, this.form.original_price);
                            }
                        });
                    } else {
                        dis = '';
                    }
                });
            }
            return dis;
        }
    },
    watch: {
        /**
         * 监听市场，改变货币的值
         * @param {string} val 市场
        */
        'form.market': function(val) {
            this.form.currency = val === 'US' ? '美元' : '人民币';
        },
        /**
         * 监听原价和现价，改变折扣的值
         * @param {string} val 折扣
        */
        discount(val) {
            this.form.discount = val === '' ? val : `-${val}%`;
        }
    },
    methods: {
        /** 显示新增/编辑弹窗 */
        show() {
            if (this.plan.id) {
                this.handleEdit();
            }
            this.checkNumber();
            this.dialogVisible = true;
        },
        /**
         * 处理编辑
        */
        handleEdit() {
            this.form = {
                ...this.plan,
                enableValue: `${this.plan.start_type}`, // 启用时间
                expiredValue: this.plan.expired_at === 0 ? '1' : '2', // 有效期
                effective_at: this.plan.effective_at === 0 ? '' : this.plan.effective_at * 1000,
                expired_at: this.plan.expired_at === 0 ? '' : this.plan.expired_at * 1000,
                discount: this.plan.discount || this.plan.discount === 0 ? `-${this.plan.discount}%` : ''
            };
        },
        /**
         * 检查已使用的积分方案数量
        */
        checkNumber() {
            api.checkNum().then((res) => {
                if (res.data.code === 10200) {
                    if (Number(res.data.data.num) > 3) {
                        this.showTips = `最多只能启用4个积分方案，请检查[审核中]、[待启用]、[已启用]中的方案，确认是否还需要${this.plan.id ? '编辑' : '新增'}方案`;
                    }
                }
            });
        },
        /**
         * 监听更改
         * @param {*} val 值
         * @param {*} prop 值
        */
        handleChange(val, prop) {
            if (prop === 'enableValue') {
                this.form.effective_at = '';
            }
            // 监听启用时间更改, 生效时间更改时，重置结束时间
            if ((prop === 'expiredValue' && val === '1') || (prop === 'effective_at' && this.form.expired_at)) {
                this.form.expired_at = '';
            }
        },
        /** * 关闭弹窗  */
        hide() {
            this.showTips = '';
            this.dialogVisible = false;
            this.form = { ...initFormVal };
            this.$refs.form.resetFields();
        },
        /**
         * 时间校验
         * @returns {*}
        */
        timeValid() {
            // 结束时间必须晚于启用时间24小时
            const param = this.form.enableValue === '2' && this.form.effective_at ? toDate(this.form.effective_at) : new Date();
            return param.getTime() + 24 * 60 * 60 * 1000;
        },
        /**
         * 点击确定按钮
        */
        sure() {
            if (this.form.enableValue === '2' && this.form.effective_at) {
                const curDate = new Date().getTime() + 2 * 60 * 60 * 1000;
                if (toDate(this.form.effective_at).getTime() < curDate) {
                    this.$$error('启用时间仅可选择2小时之后的时间');
                    return;
                }
            }
            if (this.form.expiredValue === '2' && this.form.expired_at) {
                const time = this.timeValid('expired_at');
                if (toDate(this.form.expired_at).getTime() < time) {
                    this.$$error('有效期结束时间必须晚于启用时间24小时');
                    return;
                }
            }
            this.$refs.form.validate((valid) => {
                if (valid) {
                    const dis = this.discount || this.discount === 0 ? this.discount : discount(this.form.price, this.form.original_price);
                    const params = getParams(this.form, dis);
                    this.btnLoading = true;
                    if (this.form.id) {
                        this.editSubmit(params);
                    } else {
                        this.addSubmit(params);
                    }
                }
            });
        },
        /** 新增提交
         * @param {Object} params 请求参数
        */
        addSubmit(params) {
            api.addPurchase(params).then((res) => {
                this.handleApiRes(res);
            }).catch(() => { this.btnLoading = false; });
        },
        /** 编辑提交
         * @param {Object} params 请求参数
        */
        editSubmit(params) {
            api.updatePurchase({ ...params, id: this.form.id }).then((res) => {
                this.handleApiRes(res);
            }).catch(() => {
                this.btnLoading = false;
            });
        },
        /** 处理新增/编辑接口返回
         * @param {Object} res 结果
        */
        handleApiRes(res) {
            if (res.data.code === 10200) {
                if (!this.plan.id) {
                    this.$$success('积分方案新建成功，待审核成功后启用');
                }
                this.hide();
                this.$emit('onHandleQuery');
            } else {
                this.$$error(res.data.message);
            }
            this.btnLoading = false;
        }
    }
};
</script>
<style lang="less">
@import "./edit.less";
</style>
