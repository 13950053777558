/**
 * 积分方案和方案审批表格的列
 * @param {string} type 积分方案：plan
 * @param {string} operationWidth 操作列的宽度
 * @returns {Array}
 */
export const tableColumn = (type, operationWidth) => [{
    label: '编号',
    prop: type === 'plan' ? 'id' : 'purchase_plan_id',
    minWidth: '90px'
}, {
    label: '市场',
    prop: 'market',
    minWidth: '80px'
}, {
    label: '方案名称',
    prop: 'name',
    minWidth: '160px',
    showOverflowTooltip: true
}, {
    label: '积分',
    prop: 'amount',
    minWidth: '90px'
}, {
    label: '原价',
    prop: 'original_price',
    minWidth: '90px'
}, {
    label: '现价',
    prop: 'price',
    minWidth: '90px'
}, {
    label: '省下折扣',
    prop: 'discount',
    minWidth: '90px'
}, {
    label: '启用时间',
    prop: 'effective_at',
    minWidth: '140px'
}, {
    label: '有效期',
    prop: 'expired_at',
    minWidth: '140px'
}, {
    label: '状态',
    prop: 'status',
    minWidth: '100px',
    type: ['plan']
}, {
    label: '操作',
    prop: 'operation',
    fixed: 'right',
    minWidth: operationWidth || '240px'
}].filter((item) => !item.type || (item.type && item.type.indexOf(type) > -1));
