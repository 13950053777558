const market = [{
    value: 'CN',
    label: 'CN'
}, {
    value: 'US',
    label: 'US'
}];
const expiredOptions = [{
    value: '1',
    label: '永久'
}, {
    value: '2',
    label: '选择有效期'
}];
const enableOptions = [{
    value: '1',
    label: '立即启用'
}, {
    value: '2',
    label: '定时启用'
}];
// 类型数据
const typeOptions = [{
    value: 1,
    label: '固定充值'
}];
export const initFormVal = {
    effective_at: '', // 启用时间 todo
    expiredValue: '1', // 有效期的值
    type: 1 // 类型
};
/**
 * form
*/
export const formItems = [{
    prop: 'market',
    label: '市场',
    type: 'select',
    option: market,
    placeholder: '请选择市场'
}, {
    prop: 'name',
    label: '方案名称',
    type: 'input',
    placeholder: '请输入方案名称'
}, {
    prop: 'currency',
    label: '货币',
    type: 'input',
    disabled: true
}, {
    prop: 'type',
    label: '类型',
    type: 'select',
    option: typeOptions
}, {
    prop: 'amount',
    label: '积分',
    type: 'input',
    placeholder: '输入积分'
}, {
    prop: 'original_price',
    label: '原价',
    type: 'input',
    placeholder: '输入原价'
}, {
    prop: 'price',
    label: '现价',
    type: 'input',
    placeholder: '输入现价'
}, {
    prop: 'discount',
    label: '省下折扣',
    type: 'input',
    disabled: true
}, {
    prop: 'enableValue',
    label: '启用时间',
    type: 'select',
    option: enableOptions,
    placeholder: '请选择启用时间'
}, {
    prop: 'effective_at',
    label: '',
    type: 'datetime',
    filterKey: 'enableValue',
    placeholder: '选择启用时间'
}, {
    prop: 'expiredValue',
    label: '有效期',
    type: 'select',
    option: expiredOptions
}, {
    prop: 'expired_at',
    label: '',
    type: 'datetime',
    filterKey: 'expiredValue',
    placeholder: '选择结束日期'
}];
/**
 * 积分校验
 * @param {*} rule 规则
 * @param {*} value 值
 * @param {*} callback 返回
*/
const validateAmount = (rule, value, callback) => {
    const reg = /^(\d{1,4})$/;
    if (!(reg.test(value) && value >= 1 && value <= 9999)) {
        callback('只能输入数字，必须为整数，数值范围1-9999');
    } else {
        callback();
    }
};
/**
 * 价格校验
 * @param {*} rule 规则
 * @param {*} value 值
 * @param {*} callback 返回
*/
const validatePrice = (rule, value, callback) => {
    const priceReg = /^\d+(\.\d{1,2})?$/;
    if (!(priceReg.test(value) && value >= 0.01 && value <= 9999)) {
        callback('只能输入数字，最多小数点后两位，数值范围0.01-9999');
    } else {
        callback();
    }
};

/**
 * 方案名称校验
 * @param {*} rule 规则
 * @param {*} value 值
 * @param {*} callback 返回
*/
const validateName = (rule, value, callback) => {
    const newVal = value ? value.trim() : '';
    if (!value || !newVal) {
        callback('请输入方案名称');
    } else {
        callback();
    }
};

export const rules = {
    market: [
        { required: true, message: '请选择市场', trigger: 'change' }
    ],
    name: [
        { required: true, message: '请输入方案名称', trigger: 'blur' },
        { validator: validateName, trigger: 'blur' },
        { min: 1, max: 18, message: '最多可输入18个字', trigger: 'blur' }
    ],
    type: [
        { required: true, message: '请选择类型', trigger: 'change' }
    ],
    amount: [
        { required: true, message: '请输入积分' },
        { validator: validateAmount, trigger: 'blur' }
    ],
    original_price: [
        { required: true, message: '请输入原价' }, { validator: validatePrice, trigger: 'blur' }
    ],
    enableValue: [
        { required: true, message: '请选择启用时间', trigger: 'change' }
    ],
    expiredValue: [{ required: true }],
    effective_at: [
        { required: true, message: '请选择启用时间', trigger: 'change' }
    ],
    expired_at: [
        { required: true, message: '请选择结束时间', trigger: 'change' }
    ]
};
/**
 * 折扣, 如果计算出来折扣 “-30%”， 则返回 30
 * @param {*} price 现价
 * @param {*} originalPrice 原价
 * @returns {*}
 */
export const discount = (price, originalPrice) => {
    const num = ((price / originalPrice) - 1) * 100;
    const arr = num.toString().split('.');
    const arr2 = arr[0].split('-');
    return arr2[arr2.length - 1];
};
/**
 * 获取新增/编辑接口参数
 * @param {Object} form 表单数据
 * @param {number} dis 折扣
 * @returns {*}
*/
export const getParams = (form, dis) => ({
    name: form.name,
    discount: dis,
    type: form.type,
    currency: form.market === 'US' ? 'USD' : 'CNY',
    amount: form.amount, // 积分
    price: form.price, // 现价
    original_price: form.original_price, // 原价  单位元
    market: form.market, // 市场
    start_type: form.enableValue,
    effective_at: form.enableValue === '2' ? form.effective_at / 1000 : '0', // 生效时间  时间戳
    expired_at: form.expiredValue === '2' ? form.expired_at / 1000 : '0'
});
