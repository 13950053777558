import axios from '../uitls/require'

export default {
  purchasePlanInfo(id) {
    return axios.get('/admin/purchase-plan?id=' + id)
  },
  purchasePlanList(info) {
    return axios.post('/admin/purchase-plan/list', info)
  },
  addPurchase(info) {
    return axios.post("/admin/purchase-plan/create", info)
  },
  updatePurchase(info) {
    return axios.post('/admin/purchase-plan/edit', info)
  },
  delPurchase(info) {
    return axios.post('/admin/purchase-plan/delete', info)
  },
  enablePurchase(info) {
    return axios.post('/admin/purchase-plan/enable', info)
  },
  disablePurchase(info) {
    return axios.post('/admin/purchase-plan/disable', info)
  },
  //积分方案审批 修改状态
  planChangeStatus(data) {
    return axios.post('/admin/purchase-plan/change-status', data)
  },
  //积分方案审批列表
  planAuditList(data) {
    return axios.post('/admin/purchase-plan-audit/list', data)
  },
  //积分方案审批 修改状态
  changeStatus(data) {
    return axios.post('/admin/purchase-plan-audit/change-status', data)
  },
 //检测线上积分方案数量
  checkNum() {
    return axios.get('/admin/purchase-plan/check-on-num')
  }
}
