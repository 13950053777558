<template>
    <!-- 停用 撤回 -->
    <el-dialog
        top="12%"
        :visible.sync="dialogVisible"
        width="458px"
        :modal-append-to-body="false"
        class="pop-dialog aops-opera-plan-dialog"
    >
        <div v-if="plan.operaType === 'delete'" class="aop-opera-plan-info">
            <div>确认<span class="aop-opera-del-text">删除编号【{{plan.id}}】</span>【{{plan.name}}】？</div>
            <div>删除后该方案不再展示在列表中。</div>
        </div>
        <div v-else class="aop-opera-plan-info">
            确认{{plan.operaText}}【{{plan.name}}】？
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" class="confirm-button" :loading="btnLoading" @click="confirm" >确认</el-button>
            <el-button class="cancel-button" @click="hide">取 消</el-button>
        </span>
    </el-dialog>
</template>

<script>
import api from '@/api/transcation';

export default {
    props: {
        plan: {
            type: Object,
            default: () => ({})
        }
    },
    /**
     * data
     * @returns {*}
    */
    data() {
        return {
            dialogVisible: false,
            btnLoading: false
        };
    },
    methods: {
        /**
         * 显示弹窗
        */
        show() {
            this.dialogVisible = true;
        },
        /**
         * 点击确定按钮
        */
        confirm() {
            this.btnLoading = true;
            switch (this.plan.operaType) {
                case 'disable':
                    this.changeStatus(0);
                    break;
                case 'cancel':
                    this.changeStatus(11);
                    break;
                case 'delete':
                    this.delSure();
                    break;
                default:
                    break;
            }
        },
        /**
         * 撤回方案
         * @param {number} status 11:撤回 1:启用，0:停用
        */
        changeStatus(status) {
            const data = {
                id: this.plan.id,
                status
            };
            api.planChangeStatus(data).then((res) => {
                this.handleApiRes(res);
            }).catch((error) => {
                this.$$error(error);
                this.btnLoading = false;
            });
        },
        /**
         * 删除方案
        */
        delSure() {
            api.delPurchase({
                id: [this.plan.id]
            }).then((res) => {
                this.handleApiRes(res);
            }).catch((error) => {
                this.$$error(error);
                this.btnLoading = false;
            });
        },
        /**
         * 处理接口返回
         * @param {Object} res 结果
        */
        handleApiRes(res) {
            if (res.data.code === 10200) {
                this.dialogVisible = false;
                this.$$success(res.data.message);
                this.$emit('onHandleQuery');
            } else {
                this.$$error(res.data.message);
            }
            this.btnLoading = false;
        },
        /**
         * 关闭弹窗
        */
        hide() {
            this.dialogVisible = false;
        }
    }
};
</script>
<style lang="less">
@import "./operaPlanDialog.less";
</style>
