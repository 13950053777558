<template>
  <div v-if="hasResource('purchase_plan_management')">
    <div class="plans" >
      <div class="search-form">
        <el-button v-if="hasResource('purchase_plan_add')" class="black-btn" type="primary" @click="handleAdd">新增</el-button>
        <div class="dis-fix">
          <el-form ref="form" :inline="true" :model="form">
            <el-form-item
                label="编号/名称"
                prop="id_name"
                :rules="[
                    { max: 18, message: '最多可输入18个字', trigger: 'blur' }
                ]"
            >
              <el-input v-model="form.id_name" placeholder="输入编号/名称检索" clearable></el-input>
            </el-form-item>
            <el-form-item label="市场">
              <el-select v-model="form.market" placeholder="请选择" class="select-size">
                <el-option label="全部" value></el-option>
                <el-option
                  v-for="(item) in market"
                  :key="item.value"
                  :label="item.value"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="状态">
              <el-select v-model="form.status" placeholder="请选择" class="select-size">
                <el-option
                  v-for="item in alotionStatus"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <div class="text-btn">
            <span class="serach-button" @click="handleQuery">查询</span>
            <span class="clear-button"  @click="resetQuery">清空</span>
          </div>
        </div>
      </div>
      <el-table
        v-loading="isTableLoading"
        :data="integralData"
        style="width:100%;"
        class="data-table pm-plan-table"
        header-cell-class-name="table-head"
        cell-class-name="table-cell">
        <el-table-column
            v-for="col in tableColumn"
            :key="col.prop"
            :prop="col.prop"
            :label="col.label"
            :min-width="col.minWidth"
            :show-overflow-tooltip="col.showOverflowTooltip"
            :fixed="col.fixed"
        >
            <template slot-scope="{row}">
                <!-- 现价 -->
                <span v-if="col.prop === 'price' || col.prop === 'original_price'">
                    {{row[col.prop] ? `${row.currency === 'USD' ? '$' : '¥'}${row[col.prop]}` : '--'}}
                </span>
                <!-- 折扣 -->
                <span v-else-if="col.prop === 'discount'">
                    {{row.discount ? `-${row.discount}%` : '--'}}
                </span>
                <!-- 有效期 / 启用时间 -->
                <span v-else-if="col.prop === 'expired_at' || col.prop === 'effective_at' ">
                    {{row[col.prop] | dateFilter(col.prop)}}
                </span>
                <!-- 状态 -->
                <span v-else-if="col.prop === 'status'">
                    {{row.status | statusFilter}}
                </span>
                <div v-else-if="col.prop === 'operation'" class="icon-btn">
                    <template v-for="item in getOperaBtnData(row.status)">
                        <el-button
                            v-if="!item.resource || hasResource(item.resource)"
                            :key="item.type"
                            size="mini"
                            type="text"
                            class="preview-color"
                            @click="handleOperation(item, row)"
                        >
                            <i class="iconfont">{{item.icon}}</i>{{item.text}}
                        </el-button>
                    </template>
                </div>
                <span v-else>{{row[col.prop] || '--'}}</span>
            </template>
        </el-table-column>
      </el-table>
      <div class="ta-r mg-t-18">
        <el-pagination
          :page-sizes="[10,20,50]"
          :page-size="pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          :current-page.sync="currentPage"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
      <editDialog ref="editDialog" :plan="curPlan" @onHandleQuery="handleQuery" />
      <operaPlanDialog ref="operaPlanDialog" :plan="curPlan" @onHandleQuery="handleQuery" />
    </div>
  </div>
</template>

<script>
import api from '../../../api/transcation';
import commonApi from '../../../api/common';
import { formatDate } from 'ecoplants-lib';
import editDialog from './components/edit.vue';
import operaPlanDialog from './components/operaPlanDialog';
import { tableColumn } from '../_common/planData';
import { statusOption, operationBtn } from './data';
import { initFormVal } from './data';

export default {
    components: { operaPlanDialog, editDialog },
    filters: {
        /**
         * 表格状态格式化
         * @param {number} val 状态
         * @returns {string | number}
        */
        statusFilter(val) {
            const child = statusOption.find((item) => item.value === `${val}`);
            return child ? child.label : val;
        },
        dateFilter(val, type) {
            return val === 0 ? type === 'expired_at' ? '永久' : '立即启用' : formatDate(val * 1000)
        }
    },
    /**
     * data
     * @returns {*}
    */
    data() {
        return {
            // 表格数据
            isTableLoading: false, // 表格loading
            integralData: [], // 表格数据
            pagesize: 10,
            total: 0,
            currentPage: 1,
            alotionStatus: statusOption,
            form: {...initFormVal},
            market: [], // 市场筛选项
            curPlan: {} // 当前操作的方案
        };
    },
    computed: {
        /**
         * 表格的列
         * @returns {Array}
        */
        tableColumn() {
            return tableColumn('plan');
        }
    },
    /**
     * mounted
    */
    mounted() {
        this.init();
    },
    activated() {
        if (this.$route.meta.keepAlive) {
            this.getIntegralList();
        }
    },
    methods: {
        /**
         * 初始化获取表格数据，市场数据
        */
        init() {
            this.getIntegralList();
            this.getAllMarket();
        },
        /**
         * 获取表格数据
        */
        getIntegralList() {
            this.isTableLoading = true;
            const data = {
                limit: this.pagesize,
                page: this.currentPage,
                filter: {
                    ...this.form,
                    type: ''
                }
            };
            api.purchasePlanList(data).then((res) => {
                if (res.data.code === 10200) {
                    this.integralData = res.data.data.items;
                    this.total = res.data.data.total;
                } else {
                    this.$$error(res.data.message);
                }
                this.isTableLoading = false;
            });
        },
        /**
         * 获取市场数据
        */
        getAllMarket() {
            commonApi.market().then((res) => {
                if (res.data.code === 10200) {
                    if (res.data.data) {
                        this.market = Object.keys(res.data.data).map((key) => (
                            { value: key, label: key }
                        ));
                    }
                } else {
                    this.$$error(res.data.message);
                }
            });
        },
        /**
         * 分页
         * @param {number} val 一页多少条
        */
        handleSizeChange(val) {
            this.pagesize = val;
            this.getIntegralList();
        },
        /**
         * 分页
         * @param {number} val 页码
        */
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getIntegralList();
        },
        /**
         * 新增
        */
        handleAdd() {
            this.curPlan = {};
            this.$nextTick(() => {
                this.$refs.editDialog.show();
            });
        },
        /**
         * 查询
        */
        handleQuery() {
            this.currentPage = 1;
            this.getIntegralList();
        },
        /**
         * 重置
        */
        resetQuery() {
            this.form = {...initFormVal};
            this.$refs.form.resetFields();
        },
        /**
         * 获取表格操作按钮
         * @param {number} status 积分方案状态
         * @returns {Array}
        */
        getOperaBtnData(status) {
            return operationBtn(status);
        },
        /**
         * 处理操作按钮
         * @param {Object} item 操作按钮
         * @param {Object} row 操作的积分方案
        */
        handleOperation(item, row) {
            this.curPlan = { ...row, operaText: item.text, operaType: item.type };
            switch (item.type) {
                case 'edit':
                    this.handleShowEditDialog();
                    break;
                case 'disable':
                case 'cancel':
                case 'delete':
                    this.handleShowOperaDialog();
                    break;
                default:
                    break;
            }
        },
        /**
         * 编辑
        */
        handleShowEditDialog() {
            this.$nextTick(() => {
                this.$refs.editDialog.show();
            });
        },
        /**
         * 停用/ 撤回
        */
        handleShowOperaDialog() {
            this.$nextTick(() => {
                this.$refs.operaPlanDialog.show();
            });
        }
    }
};
</script>
<style lang="less">
@import "./scss/index.less";
</style>
